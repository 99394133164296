import type { WerkgelukUser } from "~/types";

const passwordResetCode = ref("");

export const useLogin = () => {
  //Extend StrapiUser typescript with field companies
  const user = useStrapiUser<WerkgelukUser>();
  const isLoggedIn = computed(() => !!user.value);

  const { logout, getProviderAuthenticationUrl } = useStrapiAuth();

  const providerLogin = async (provider: string) => {
    await navigateTo(getProviderAuthenticationUrl(provider), {
      external: true,
    });
  };

  const login = (username: string, password: string) => {
    const { login } = useStrapiAuth();

    return login({
      identifier: username,
      password,
    });
  };

  const register = (
    firstName: string,
    infix: string,
    lastName: string,
    phone: string,
    email: string,
    password: string,
  ) => {
    const { register } = useStrapiAuth();
    return register({
      username: email.toLowerCase(),
      email: email.toLowerCase(),
      password,
      contactInfo: {
        firstName: firstName,
        infix: infix,
        lastName: lastName,
        email: email,
        phone: phone,
      },
    });
  };

  const requestPasswordReset = (email: string) => {
    const { forgotPassword } = useStrapiAuth();
    return forgotPassword({
      email,
    });
  };

  const resetPassword = (password: string, passwordConfirmation: string) => {
    const { resetPassword } = useStrapiAuth();
    return resetPassword({
      code: passwordResetCode.value,
      password,
      passwordConfirmation,
    });
  };

  const changePassword = (
    currentPassword: string,
    password: string,
    passwordConfirmation: string,
  ) => {
    const { changePassword } = useStrapiAuth();
    return changePassword({
      currentPassword,
      password,
      passwordConfirmation,
    });
  };

  const refresh = async () => {
    const { fetchUser } = useStrapiAuth();
    await fetchUser();
  };

  const update = async (data: object) => {
    const postData = new FormData();
    postData.append("data", JSON.stringify(data));

    const client = useStrapiClient();
    await client("/users/me", {
      method: "PUT",
      body: postData,
    });
    await refresh();
  };

  return {
    passwordResetCode,
    user,
    isLoggedIn,
    login,
    logout,
    providerLogin,
    register,
    requestPasswordReset,
    resetPassword,
    changePassword,
    update,
    refresh,
  };
};
